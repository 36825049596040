<template>
    <ion-page>
        <ion-header translucent>
            <ion-toolbar>
                <ion-title>Incidents</ion-title>
            </ion-toolbar>
            <ion-toolbar>
                <ion-searchbar show-cancel-button="focus" v-model="query" @ionChange="filter"></ion-searchbar>
            </ion-toolbar>
        </ion-header>
        <ion-content fullscreen>
            <ion-list>
                <ion-item v-if="loading">
                    <ion-label>Loading</ion-label>
                    <ion-spinner color="primary"></ion-spinner>
                </ion-item>
                <template v-else-if="incidents.length">
                    <ion-item button v-for="(incident, index) in incidents"
                              :key="index" :href="'/incident/' + incident.id" class="option"
                              :color="incident.is_accident ? 'warning' : 'light'">
                        {{ incident.identifier }} : {{ category(incident) }} at {{ incident.location }}
                        <ion-note slot="end">{{ formatDate(incident.occurred_at) }}</ion-note>
                    </ion-item>
                </template>
                <ion-item v-else>
                    <ion-label>
                        <ion-text color="primary">You do not have any reported incidents.</ion-text>
                    </ion-label>
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonToolbar, IonTitle, IonSearchbar, IonContent, IonList, IonLabel, IonSpinner, IonItem, IonText, IonNote, toastController } from '@ionic/vue';
import Api from '@/services/Api';
import moment from 'moment';

export default {
    name: 'Incidents',
    components: { IonPage, IonHeader, IonToolbar, IonTitle, IonSearchbar, IonContent, IonList, IonLabel, IonSpinner, IonItem, IonText, IonNote },
    data(){
        return {
            loading: false,
            error: null,
            query: '',
            incidents: [],
        };
    },
    async mounted(){
        this.loading = true;
        const response = await Api.incidents.get();
        if(response.success){
            this.incidents = response.incidents;
        } else {
            this.error = response.error || 'Could not load incidents. Are you online?';
            await this.showLoadingError();
        }
        this.loading = false;
    },
    methods: {
        filter(){
            const query = this.query.toLowerCase(),
                items = Array.from(document.getElementsByClassName('option'));
            requestAnimationFrame(() => {
                items.forEach(item => {
                    item.style.display = item.textContent.toLowerCase().indexOf(query) > -1 ? 'block' : 'none';
                });
            });
        },
        category(incident){
            const category = [];
            if(incident.entities.includes('vehicle')){
                category.push('Motor Vehicle Accident');
            }
            if(incident.entities.includes('equipment')){
                category.push('Mobile Equipment');
            }
            if(incident.entities.includes('property')){
                category.push('Property Or Non-Mobile Equipment');
            }
            if(incident.injuries.length){
                category.push('Injury');
            }
            return category.join('/');
        },
        formatDate(date){
            return date ? moment(date).format('MM/DD/YY, h:mma') : '';
        },
        async showLoadingError(){
            const toast = await toastController
                .create({
                    message: this.error,
                    duration: 2000
                });
            return toast.present();
        },
    },
};
</script>
